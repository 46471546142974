import React from 'react';
import Hero from '../components/Hero';
import Slide from '../components/Slide'
import Section from "../components/Section"

function HomePage(props) {

  return (
    <div className="homePage">
      <Hero className="m-20" title={props.title} subtitle={props.subtitle} text={props.text}/>
    <Slide/>
    <Section/>
    </div>
  );
}

export default HomePage;
