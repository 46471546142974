import React from 'react';
import Container from "react-bootstrap/Container"
import "../index.css";

function ThankYouPage() {
    return (
        <Container className="thankYou">
            <div className="card">
                <div className="card-body">
                    <h5>
                        Thank You!
                    </h5>
                    <h2 className="card-title">Your data was succesfully sent.</h2>
                    <h5 className="card-text">We Will Contact You Back.</h5>
                </div>
            </div>
            <div className="card-footer text-muted">
                gem<b>i</b>n<b>i</b> software
            </div>
        </Container>
    );
}

export default ThankYouPage;