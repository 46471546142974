import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button"
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import "../index.css"


function Slide() {
    return (
        <Carousel>

            <Carousel.Item interval={2000}>
                <div className="geminiImage"></div>
            </Carousel.Item>

            <Carousel.Item interval={2000}>
                <div className="aboutImage"></div>
                <Button className="btn btn-danger"><Link className="carousel" to="/about">Find Out More</Link></Button>
            </Carousel.Item>

            <Carousel.Item interval={2000}>
                <div className="contactImage"></div>
                <Button className="btn btn-danger text-light"><Link className="carousel" to="/contact">Contact Us</Link></Button>
            </Carousel.Item>

        </Carousel>
    );
}

export default Slide;