import React from 'react'
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container"
import Col from 'react-bootstrap/Col'

import "../index.css"

function Section() {

    return (

        <Container className="p-30 m-30">
            <Row>
                <Col lg={4} md={12} sm={12}>
                    <div className="card border-0" >
                        <div className="card-body text-justify">
                            <h5 className="card-title display-6">Knowledge and Innovation</h5>
                            <p className="card-text">Relying on years of experience, we turn our knowledge and creativity into modern work.</p>
                        </div>
                    </div>
                </Col>
                <Col lg={4} md={12} sm={12}>
                    <div className="card border-0" >
                        <div className="card-body text-justify">
                            <h5 className="card-title display-6">Unlimited support</h5>
                            <p className="card-text">We are avaliable to all our customers at any time of the day. You can always turn to us for help or advice. Communication is possible in several foreign languages.</p>
                        </div>
                    </div>
                </Col>
                <Col lg={4} md={12} sm={12}>
                    <div className="card border-0"  >
                        <div className="card-body text-justify">
                            <h5 className="card-title display-6">Modern Technologies</h5>
                            <p className="card-text technologies">
                                <ul>
                                    <li>Web Aplications</li>
                                    <li>ASP.NET</li>
                                    <li>Cloud based computing</li>
                                    <li>Aritifical inteligence AI</li>
                                    <li>Machine learning</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Section