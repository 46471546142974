import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Animated} from "react-animated-css"
import "../index.css"


function Hero(props) {
  return (
    <Animated animationIn="slideInRight"  isVisible={true} animationInDuration={2000}>
    <Jumbotron id="heroComponent" className="bg-transparent jumbotron-fluid p-20">
      <Container fluid={true}>
        <Row className="justify-content-center p-0">
          <Col md={8} sm={12}>
            {props.title && <h2 >{props.title}</h2>}
            {props.subtitle && <h3>{props.subtitle}</h3>}
            {props.text && <h4 className="lead">{props.text}</h4>}
          </Col>
        </Row>
      </Container>
    </Jumbotron>
    </Animated>
  );
}

export default Hero;