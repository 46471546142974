import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import "../index.css";


function Footer() {

    return (
        <footer >
            <p><a href="https://geminisoftware.rs/">gem<b>i</b>n<b>i</b> software</a> © {new Date().getFullYear()}            </p>
        </footer>
    )

}

export default Footer