import React, { Component } from 'react';
import { BrowserRouter as Layout, Route, Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from "react-bootstrap/Navbar"
import NavbarBrand from 'react-bootstrap/NavbarBrand';
import Nav from "react-bootstrap/Nav"
import Button from 'react-bootstrap/Button'

import Footer from './components/Footer';
import HomePage from "./pages/HomePage"
import ContactPage from "./pages/ContactPage"
import ThankYouPage from './pages/ThankYouPage'

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "GEMINI SOFTWARE",
            headerLinks: [
                { title: "Home", path: "/" },
                { title: "Contact", path: "/contact" }
            ],
            home: {
                title: "Grease the wheels.",
                subtitle: "With us it is possible."
            },
            contact: {
                title: "Get in touch with us",
            },
        }
    }

    render() {

        return (

            <Layout>
                <Container fluid={true}>
                    <Navbar className="fixed-top" expand="lg" id="header"><h1>
                        <NavbarBrand id="navbar" className="text-light"><Link to="/">gem<b>i</b>n<b>i</b>software</Link></NavbarBrand></h1>
                        <Navbar.Toggle className="border-0" aria-controls="navbar-toggle" />
                        <Navbar.Collapse id="navbar-toggle">
                            <Nav className="ml-auto">
                                <Button className="btn"><Link className="nav-link text-light " to="/">Home</Link></Button>
                                <Button className="btn"> <Link className="nav-link text-light" to="/contact">Contact</Link></Button>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                    <Route path="/" exact render={() => <HomePage title={this.state.home.title} subtitle={this.state.home.subtitle} text={this.state.home.text} />} />
                    <Route path="/contact" render={() => <ContactPage title={this.state.contact.title} />} />
                    <Route path="/thankyou" render={() => <ThankYouPage />} />
                    <Footer />
                </Container>
            </Layout>

        );
    }
}
export default App;
