
import React, { Component } from "react";
import Recaptcha from "react-recaptcha"
import { Redirect } from 'react-router-dom'
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import gemini_address from "../../src/assets/images/gemini_address.png"
import gemini_telephone from "../assets/images/gemini_telephone.png"

import "../index.css";

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactName: "",
            contactEmail: "",
            contactMessage: "",
            handleSubmit: false,
            isVerified: false,
            toThankYouPage: false,
            show: false,
        }
    }
    handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value
        })
    }
    handleSentEmail = () => {
        if (this.state.isVerified) {
            this.setState({
                handleSubmit: true,
            })
        } else {
            this.setState({
                windowAlert: true
            })
        }
    }
    verifyCallback = (response) => {
        if (response) {
            this.setState({
                isVerified: true
            })
        }
    }
    closeAlert = () => {
        this.setState({ show: false });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            contactName: this.state.contactName,
            contactEmail: this.state.contactEmail,
            contactMessage: this.state.contactMessage
        }
        if (this.state.isVerified) {
            fetch(`api/SendEmailForm/SendEmailForm`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(data),
            })
                .then(response => {
                    this.setState(function (prevState, props) {
                        return { toThankYouPage: !prevState.toThankYouPage }
                    });
                })
        } else {
            this.setState({ show: true })
        }
    }
    render() {
        if (this.state.toThankYouPage === true) {
            return <Redirect to='/thankyou' />
        }
        return (
            <div className="container">
                <Row className="justify-content-start contact container" expand="lg" >
                    <Col sm={12}>
                        <h2>
                            You like what you see?
                        </h2>
                        <h3>Contact Us</h3>
                    </Col>
                </Row>
                <Row className="justify-content-start contact" >
                    <Col id="contact" sm={12} md={6}>
                        <Form className="contactUs" onSubmit={this.handleSubmit}>
                            <Form.Group>
                                <Form.Label htmlFor="contactName">
                                    Full name
                                </Form.Label>
                                <Form.Control className="input-form"
                                    required
                                    id="contactName"
                                    name="contactName"
                                    type="text"
                                    value={this.state.contactName}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="contactEmail">
                                    Email address
                                </Form.Label>
                                <Form.Control className="input-form"
                                    required
                                    id="contactEmail"
                                    name="contactEmail"
                                    type="email"
                                    value={this.state.contactEmail}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                            <Alert show={this.state.show} variant="warning">
                                <Alert.Heading>You need to confirm that you are not a robot!</Alert.Heading>
                                <p>Check the field under the form</p>
                                <hr />
                                <div className="d-flex justify-content-end">
                                    <Button onClick={this.closeAlert} variant="outline-success">
                                        OK
                                    </Button>
                                </div>
                            </Alert>
                            <Form.Group>
                                <Form.Label htmlFor="contactMessage">
                                    Message
                                </Form.Label>
                                <Form.Control className="input-form"
                                    required
                                    id="contactMessage"
                                    name="contactMessage"
                                    as="textarea"
                                    row="10"
                                    value={this.state.contactMessage}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Recaptcha lg={4} md={4} sm={4} xs={4}
                                    sitekey="6Lfa798UAAAAAGVb1NcTNp8DCB5uXQB7ZbuXi7bD"
                                    render="explicit"
                                    verifyCallback={this.verifyCallback}
                                />
                            </Form.Group>
                            <Button
                                className="btn btn-danger"
                                variant="primary"
                                type="submit"
                                onClick={this.handleSentEmail}
                            >
                                Submit
                            </Button>
                        </Form>
                    </Col>
                    <Col sm={12} md={4}>
                        <div class="card-body contactDetails">
                            <h5>Address</h5>
                            <p>Kneginje Milice 65<br />
                                35 000 Jagodina</p>
                            <h5>Email</h5>
                            <p><a href="mailto:info@geminisoftware.rs">info@geminisoftware.rs</a></p>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default ContactPage;

